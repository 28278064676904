import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'
import { Suspense, useContext } from 'react'

import normalizeCss from 'normalize.css/normalize.css?url'
import themeCss from './style/theme.css?url'
import globalCss from './style/global.css?url'

import { DesktopFooter, InlineFooter } from './components/global/Footer'
import { EventFilterProvider } from './features/eventFeed/EventFilterContext'
import Header from './components/global/Header'
import { ApolloStateContext } from './graphql/apolloStateContext'

import style from './components/App/App.module.css'
// import { CommandPalette } from './components/CommandPalette/CommandPalette'
// import { ClientOnly } from './utilities/ClientOnly'
import { Page } from './components/Page/Page'
// import { ButtonAsLink } from './components/Button'
import { ClientConfig, prepareClientConfig } from './configClient'
import { LinksFunction, LoaderFunctionArgs } from '@remix-run/cloudflare'
import { authHandler } from '../server/features/auth/routes/authHandler'
import { InstagramWebviewWarning } from './components/InstagramWebviewWarning/InstagramWebviewWarning'
import { Global } from '@emotion/react'
import { globalStyles } from './style/globalStyles'

export async function loader({ request, context }: LoaderFunctionArgs) {
  await authHandler(request, context)

  return json({
    config: prepareClientConfig(context.config),
  })
}

export function useRootLoader() {
  return useRouteLoaderData('root') as {
    config: ClientConfig
  }
}

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: normalizeCss },
  { rel: 'stylesheet', href: themeCss },
  { rel: 'stylesheet', href: globalCss },
]

export function Layout({ children }: { children: React.ReactNode }) {
  let initialState = useContext(ApolloStateContext)

  let isInstagram = /instagram/i.test(navigator.userAgent)

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.5.0/css/all.css"
          integrity="sha384-j8y0ITrvFafF4EkV1mPW0BKm6dp3c+J9Fky22Man50Ofxo2wNe5pT1oZejDH9/Dt"
          crossOrigin="anonymous"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap"
          rel="stylesheet"
        />

        <Meta />
        <Links />
      </head>
      <body>
        <div>
          <Global styles={globalStyles} />
          {/* <ClientOnly>
            <CommandPalette />
          </ClientOnly> */}

          <div className={style.container}>
            {!isInstagram && <Header />}
            <div className={style.belowHeader}>
              <EventFilterProvider>
                {isInstagram ? <InstagramWebviewWarning /> : children}
              </EventFilterProvider>
            </div>
            <DesktopFooter />
          </div>
          <div className={style.mobileFooter}>
            <InlineFooter />
          </div>
        </div>
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.__APOLLO_STATE__=${JSON.stringify(
              initialState,
            ).replace(/</g, '\\u003c')};`,
          }}
        />
        <Scripts />
      </body>
    </html>
  )
}

export default function App() {
  return (
    // comment out this line if you're seeing "cannot render router inside another router"
    <Suspense fallback={<p>Loading...</p>}>
      <Outlet />
    </Suspense>
  )
}

export function ErrorBoundary() {
  let error = useRouteError()

  // if (config.mode === 'production') {
  //   return (
  //     <Page title="Something went wrong">
  //       <p>We're working on it.</p>
  //       <ButtonAsLink to="/">Go to Home</ButtonAsLink>
  //       <ButtonAsLink to="/support">Get support</ButtonAsLink>
  //     </Page>
  //   )
  // }

  console.error(error)
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <Page title="404 not found">
          <p>There's no signal. Is your mic plugged in?</p>
        </Page>
      )
    }

    return (
      <Page title="Unhandled thrown response">
        <p>
          {error.status} {error.statusText}
        </p>
      </Page>
    )
  }

  let errorInstance: Error
  if (error instanceof Error) {
    errorInstance = error
  } else {
    let errorString: string

    if (error == null) {
      errorString = 'Unknown Error'
    } else if (typeof error === 'object' && 'toString' in error) {
      errorString = error.toString()
    } else {
      errorString = JSON.stringify(error)
    }
    errorInstance = new Error(errorString)
  }

  return (
    <div className={style.errorContainer}>
      <h1>{errorInstance.message}</h1>
      {errorInstance.stack && (
        <code className={style.errorStackTrace}>{errorInstance.stack}</code>
      )}
    </div>
  )
}
